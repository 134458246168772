import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image';
import "./index.scss"

function imageCaption(imageData) {
  if (imageData.title) {
    return (
      <figcaption className="inline-image__caption">{imageData.title}</figcaption>
    )
  } else {
    return null
  }
}

function styleClasses(imageData) {
  if (imageData.title) {
    return 'inline-image--has-caption'
  }
}

export default function InlineImage({imageData, classes, extraProps}) {
  return (
    <figure className={['inline-image', classes, styleClasses(imageData)].join(' ')}>
      <GatsbyImage 
        image={imageData.gatsbyImageData}
        alt={imageData.alt} 
        {...extraProps}
        />
      { imageData.title && imageCaption(imageData)}
    </figure>
  )
}
