import React, { useEffect } from "react";
import { InstagramEmbed, XEmbed, TikTokEmbed } from 'react-social-media-embed';

const InlineSocialMediaEmbed = ({ socialMediaData }) => {
  const url = socialMediaData.url
  const type = url.includes('instagram') ? 'instagram' :
    url.includes('x') || url.includes('twitter') ? 'x' : 
    url.includes('tiktok') ? 'tiktok' : 'other'

  const getEmbedHtml = ({ type, url }) => {
    switch (type) {
      case "instagram":
        return (
          <InstagramEmbed url={url} width={328} />
        );
      case "x":
        return (
          <XEmbed url={url} width={325} />
        );
      case "tiktok":
        return (
          <TikTokEmbed url={url} width={325} />
        );
      default:
        return <p>Unsupported embed type.</p>;
    }
  };

  return <div>{getEmbedHtml({type, url})}</div>;
};

export default InlineSocialMediaEmbed;
