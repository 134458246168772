import React, { useRef } from "react"
import InlineImage from '../InlineImage'
import HalftoneImage from '../HalftoneImage'
import "./index.scss"
import ParallaxStyle from "../ParallaxStyle";
import { GatsbyImage } from 'gatsby-plugin-image';


function themeClasses(colorTheme) {
  return 'chapter-cover--theme-'.concat(colorTheme);
}
function layoutClasses(layout) {
  return 'chapter-cover--layout-'.concat(layout);
}
function heightClasses(height) {
  return 'chapter-cover--height-'.concat(height);
}

export default function ChapterCover({chapterData}) {

  const chapterRef = useRef(null)
  const {style} = ParallaxStyle(-.15, chapterRef.current )
  return (
    <div ref={chapterRef} className={['page-section__content chapter-cover', themeClasses(chapterData.colorTheme), layoutClasses(chapterData.layout), heightClasses(chapterData.height)].join(' ')}>

      <div className={`chapter-cover__text ${chapterData.image ? 'chapter-cover__text--has-image' : ''}`}>

        { chapterData.pretitle && (
          <p className="chapter-cover__pretitle">{chapterData.pretitle}</p>
        )}
        
        { chapterData.title && (
          <h2 className="chapter-cover__title">{chapterData.title}</h2>
        )}

        { chapterData.subtitle && (
          <p className="chapter-cover__subtitle">{chapterData.subtitle}</p>
        )}
      </div>

      { chapterData.introText && (
        <p className="chapter-cover__introtext">{chapterData.introText}</p>
      )}

      { chapterData.image && (
        <div className="chapter-cover__image">
          <GatsbyImage 
            image={chapterData.image.gatsbyImageData}
            alt={chapterData.image.alt}
            objectFit="contain"
            />
        </div>
      )}


      {/*{ chapterData.image && !chapterData.backgroundImage && (
        <div
          style={{
            bottom: '0px',
            right: 0,
            position: 'absolute', 
            transform: style.transform 
          }}
        >
          <HalftoneImage imageUrl={chapterData.image.gatsbyImageData.placeholder.fallback}/>
        </div>
      )}*/}
      {
        chapterData.backgroundImage && (
          <div
          style={{
            // bottom: '0px',
            // right: 0,
            // position: 'absolute', 
            // transform: style.transform 
          }}
          >
          <InlineImage imageData={chapterData.backgroundImage} classes="chapter-cover__background-image" />
          
        </div>
      )}

    </div>
  )
}
