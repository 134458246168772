import React, { useRef, useState, useEffect } from 'react';
import usaSVG from '../../images/svg/usmap-mcp-psa-styles.svg';
import './index.scss'
import InlineColumn from '../InlineColumn';
import InlineVideoEmbed from "../InlineVideoEmbed"
import InlineImage from "../InlineImage"

// Example map of the U.S. as SVG path data
const usa = {
  t: 49.3931,
  r: -66.95,
  b: 24.545874,
  l: -124.75,
};

function SVGMap({ mapData }) {

  const points = mapData.mapPoints.map(mp => ({
    name: mp.headline,
    lat: mp.location.latitude,
    lng: mp.location.longitude
  }))

  const containerRef = useRef(null);
  const usaRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [isSectionOpen, setIsSectionOpen] = useState(false);
  const [isChangingContent, setIsChangingContent] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);

  // Function to project latitude and longitude onto the SVG dimensions
  const calculatePosition = (lat, long) => {

    const top = usa.t;
    const right = usa.r;
    const bottom = usa.b;
    const left = usa.l;

    const width = right - left;

    // Apply Mercator projection to latitude
    const mercatorLat = Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360));
    const mercatorTop = Math.log(Math.tan(Math.PI / 4 + (top * Math.PI) / 360));
    const mercatorBottom = Math.log(Math.tan(Math.PI / 4 + (bottom * Math.PI) / 360));

    // Normalize latitude and longitude within the bounding box
    const latNormalized = (mercatorTop - mercatorLat) / (mercatorTop - mercatorBottom);
    const longNormalized = (long - left) / width;

    // Convert normalized values to percentages
    const markerTop = latNormalized * 100;
    const markerLeft = longNormalized * 100;

    // Adjust for marker dimensions to place the bottom of the marker at the coordinates
    const adjustedMarkerTop = markerTop 
    const adjustedMarkerLeft = markerLeft 
    // Set position within the map container
    const res = {
      top: adjustedMarkerTop - 6 + '%',
      left: adjustedMarkerLeft - 2 + '%',
    };
    return res
  };

  useEffect(() => {
    if (containerRef.current && usaRef.current) {
      const markers = points.map((p) => ({name: p.name, ...calculatePosition(p.lat, p.lng)}));
      setMarkers(markers);
    }
  }, []);

  const handlePointClick = (pointIndex) => {
    setIsChangingContent(true);
    setTimeout(() => {
      setSelectedPoint(pointIndex);
    }, 500);
    setTimeout(() => {
      setIsChangingContent(false);
    }, 800);
    setIsSectionOpen(true);
  };


  return (
    <div className={`${mapData.parent != 'superCard' ? "page-section__content" : ""}`}>
      <div className='svg-map__wrapper'>
        <div
          className={`svg-map__container ${!mapData.showContent ? 'svg-map__container--not-interactive' : ''}`}
          ref={containerRef}
        >
          <img ref={usaRef} src={usaSVG} alt="Map of the mainland United States" />
          {markers.map((m, i) => {
            const pinStyle = {
              top: m.top,
              left: m.left,
            }
            if (i == selectedPoint) {
              pinStyle.backgroundColor = '#655DC6'
            }
            return <div
              key={`pin${i}`}
              className='svg-map__marker'
              style={pinStyle}
              alt={`Marker ${i}`}
              onClick={() => {
                if (mapData.showContent) {
                  handlePointClick(i)
                }
              }}
            >
            {mapData.showContent && <p className="svg-map__label">{m.name}</p>}
            </div>
          })}
          
        </div>

        {mapData.showContent && (selectedPoint || selectedPoint == 0) && 
          <div className={`svg-map__point-info map-point-info ${isChangingContent ? 'changing-content' : ''}`} style={{
            flex: isSectionOpen ? 1 : 0,
            opacity: isSectionOpen && !isChangingContent ? 1 : 0,
            // padding: isSectionOpen ? '20px' : 0
          }}>

            {isSectionOpen && mapData.mapPoints[selectedPoint].mainVisual && (
              mapData.mapPoints[selectedPoint].mainVisual.map(visual => {
                switch (visual.__typename) {
                  case "DatoCmsInlineVideoEmbed":
                    return <InlineVideoEmbed videoData={visual} key={visual.id} />
                  case  "DatoCmsInlineImage":
                    return <InlineImage imageData={visual.image} key={visual.id} />
                }
              })
            )}

            {isSectionOpen && mapData.mapPoints[selectedPoint].headline && (
              <h3 className="map-point-info__headline">{mapData.mapPoints[selectedPoint].headline}</h3>
            )}

            {isSectionOpen && mapData.mapPoints[selectedPoint].displayLocation && (
              <p className="map-point-info__display-location">{mapData.mapPoints[selectedPoint].displayLocation}</p>
            )}

            {isSectionOpen && mapData.mapPoints[selectedPoint].mainContent.map(column => 
              <InlineColumn 
                key={column.id} 
                columnData={column} 
              />
            )}

            {isSectionOpen && (
              <a href="#" className="map-point-info__close-btn" onClick={(event) => {
                event.preventDefault()
                setIsSectionOpen(false)
              }} >
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="32" cy="32" r="32" fill="white" />
                  <path
                    d="M32 6C38.8956 6 45.5088 8.73928 50.3848 13.6152C55.2607 18.4912 58 25.1044 58 32C58 38.8956 55.2607 45.5088 50.3848 50.3848C45.5088 55.2607 38.8956 58 32 58C25.1044 58 18.4912 55.2607 13.6152 50.3848C8.73928 45.5088 6 38.8956 6 32C6 25.1044 8.73928 18.4912 13.6152 13.6152C18.4912 8.73928 25.1044 6 32 6ZM32 28.8466L25.9606 22.8071C25.5424 22.389 24.9752 22.154 24.3839 22.154C23.7925 22.154 23.2253 22.389 22.8071 22.8071C22.389 23.2253 22.154 23.7925 22.154 24.3839C22.154 24.9752 22.389 25.5424 22.8071 25.9606L28.8466 32L22.8071 38.0394C22.6001 38.2465 22.4358 38.4923 22.3238 38.7628C22.2117 39.0334 22.154 39.3233 22.154 39.6161C22.154 39.909 22.2117 40.1989 22.3238 40.4695C22.4358 40.74 22.6001 40.9858 22.8071 41.1929C23.0142 41.3999 23.26 41.5642 23.5305 41.6762C23.8011 41.7883 24.091 41.846 24.3839 41.846C24.6767 41.846 24.9666 41.7883 25.2372 41.6762C25.5077 41.5642 25.7535 41.3999 25.9606 41.1929L32 35.1534L38.0394 41.1929C38.2465 41.3999 38.4923 41.5642 38.7628 41.6762C39.0334 41.7883 39.3233 41.846 39.6161 41.846C39.909 41.846 40.1989 41.7883 40.4695 41.6762C40.74 41.5642 40.9858 41.3999 41.1929 41.1929C41.3999 40.9858 41.5642 40.74 41.6762 40.4695C41.7883 40.1989 41.846 39.909 41.846 39.6161C41.846 39.3233 41.7883 39.0334 41.6762 38.7628C41.5642 38.4923 41.3999 38.2465 41.1929 38.0394L35.1534 32L41.1929 25.9606C41.3999 25.7535 41.5642 25.5077 41.6762 25.2372C41.7883 24.9666 41.846 24.6767 41.846 24.3839C41.846 24.091 41.7883 23.8011 41.6762 23.5305C41.5642 23.26 41.3999 23.0142 41.1929 22.8071C40.9858 22.6001 40.74 22.4358 40.4695 22.3238C40.1989 22.2117 39.909 22.154 39.6161 22.154C39.3233 22.154 39.0334 22.2117 38.7628 22.3238C38.4923 22.4358 38.2465 22.6001 38.0394 22.8071L32 28.8466Z"
                    fill="#181817"
                  />
                </svg>
              </a>
            )}

          </div>
        }
      </div>
    </div>
  );
}

export default SVGMap;
