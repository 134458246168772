import React from "react"
import "./index.scss"
import SlickSlider from "react-slick"
import InlineLinkCard from "../InlineLinkCard"


export default function MiscLinksSlider({ sliderData }) {

	const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    infinite: false,
    variableWidth: true,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  }

	return (	
		<div className="modals-slider page-section__content">
			<SlickSlider {...sliderSettings}>
				{sliderData.links.map((link, index) => {
					return <InlineLinkCard key={`misc-link-${index}`} cardData={{...link, parent: 'miscSlider'}}/>
				})}
			</SlickSlider>

		</div>
	)
 
}
