import React, { useEffect, useRef, useState } from "react"
import InternalLink from "../InternalLink"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { StructuredText } from "react-datocms"
import "./index.scss"

export default function InlineQuote({ quoteData }) {

  const quoteWrapperRef = useRef(null);
  const imageRef = useRef(null);

  const [measurements, setMeasurements] = useState({
    distanceToBottom: 0,
    viewportHeight: 0,
    wrapperBottom: 0,
    wrapperHeight: 0,
    quoteWrapperWidth: 0,
    wrapperTop: 0
  });

  useEffect(() => {
    const quoteWrapper = quoteWrapperRef.current;
    if (!quoteWrapper) return;
    // if (!quoteWrapper || !imageRef.current) return;

    const calculatePositions = () => {
      if (quoteData.authorImage) {
        const vh = window.innerHeight;
        const quoteWrapperRect = quoteWrapper.getBoundingClientRect();
        let distanceToBottom = vh - quoteWrapperRect.bottom
        let imageHeight = imageRef.current.getBoundingClientRect().height
        let res = {
          distanceToBottom: distanceToBottom,
          viewportHeight: vh,
          quoteWrapperBottom: quoteWrapperRect.bottom,
          quoteWrapperHeight: quoteWrapperRect.height,
          quoteWrapperWidth: quoteWrapperRect.width,
          quoteWrapperTop: quoteWrapperRect.top,
          imageHeight
        }
        setMeasurements(res);
      }
    };

    // Initial calculation
    calculatePositions();

    // Add scroll and resize listeners
    window.addEventListener('scroll', calculatePositions);
    window.addEventListener('wheel', calculatePositions);
    window.addEventListener('resize', calculatePositions);

    return () => {
      window.removeEventListener('scroll', calculatePositions);
      window.removeEventListener('wheel', calculatePositions);
      window.removeEventListener('resize', calculatePositions);
    };
  }, []);

  const getStickyPosition = () => {

    // override when the quote wrapper width is too narrow
    if (measurements.quoteWrapperWidth < 400) {
      return 0
    }

    if (measurements.distanceToBottom > 0) {
      return 0
    } else {
      const res = Math.min( measurements.quoteWrapperHeight - measurements.imageHeight, measurements.distanceToBottom * -1)
      return res
    }
  }
  
  return (
    <blockquote className={`inline-quote inline-quote--theme-${quoteData.colorTheme} ${quoteData.authorImage ? 'inline-quote--has-image': ''}`} ref={quoteWrapperRef}>

      <div className="inline-quote__quote-text">
        <StructuredText
          data={quoteData.quoteText}
          renderLinkToRecord={({ record, children }) => {
            return <InternalLink record={record} classes="" children={children} />
          }}
        />
      </div>

      <cite className="inline-quote__author" >
        <p className="inline-quote__author-name">{quoteData.authorName}</p>
        <p className="inline-quote__author-title">{quoteData.authorTitle}</p>
      </cite>

      {quoteData.authorImage && 
        <div ref={imageRef} className="inline-quote__image" style={{bottom: getStickyPosition()}}>
          <GatsbyImage
            image={quoteData.authorImage.gatsbyImageData}
            alt={quoteData.authorImage.alt}
            content="contain"
          />
        </div>
      }
    </blockquote>
  )
}
