import React from "react"
import { Link } from "gatsby"
import { StructuredText } from 'react-datocms'
import InlineVideoEmbed from '../InlineVideoEmbed'
import InlineImage from '../InlineImage'
import InlineLinkCard from '../InlineLinkCard'
import InlineQuote from '../InlineQuote'
import LTARotatingTabs from '../LTARotatingTabs'
import InlineSocialMediaEmbed from '../InlineSocialMediaEmbed'
import InternalLink from '../InternalLink'
import InlineStatistic from '../InlineStatistic'
import InlineEveryactionForm from '../InlineEveryactionForm'
import "./index.scss"
import { FaCloud, FaHeart, FaSmile, FaStar } from "react-icons/fa"

const agendaProps = {
  title: 'Advancing our long term agenda',
  items: [
    {
      title: "Home",
      text: "Lorem ipsum dolor sit amet consectetur numquam dicta soluta. Molestiae nesciunt veniam aliquam porro, deleniti pariatur harum? Porro, culpa? Voluptatibus illo vero repellat id neque? Earum facere necessitatibus quis voluptas soluta tempora?",
      color: "#FD495C",
      icon: <FaStar color="white" size='2em'/>
    },
    {
      title: "Profile",
      text: "Lorem ipsum dolor sit amet, corrupti sapiente distinctio delectus dolores! Placeat omnis repellat vitae at, id cupiditate odio iusto quasi esse vero autem ex dolores, soluta illo aliquid ad, similique rem! Earum.",
      color: "#1F1646"  ,
      icon: <FaHeart color="white" size='2em'/>
    },
    {
      title: "Messages",
      text: "Lorem, ipsum dolor sit amet possimus quo incidunt voluptatem, eius rerum quibusdam ratione pariatur, distinctio tempore ipsa sint vel nesciunt fuga, omnis optio est quasi velit? Dolorum animi maxime repellat. Voluptates culpa voluptas illum!",
      color: "#655DC6"  ,
      icon: <FaSmile color="white" size='2em'/>
    },
    {
      title: "Settings",
      text: "Lorem ipsum dolor sit amet consectetur dolorum? Mollitia, esse beatae. Quam doloremque, quaerat quibusdam soluta odit ipsam non, corporis quae sunt iste libero nemo? Perspiciatis ipsa rem aut harum totam nihil fuga molestias?",
      color: "#18988B"  ,
      icon: <FaCloud color="white" size='2em'/>
    }
  ]
}

function columnStyleClasses(backgroundColor) {
  if (backgroundColor) {
    return 'section-layout__column--background-'.concat(backgroundColor)
  }
}

export default function InlineColumn({columnData, columnClasses=''}) {

  return (
    <div key={columnData.id} className={['section-layout__column standard-content', columnClasses, columnStyleClasses(columnData.backgroundColor)].join(' ')}>
      <StructuredText 
        data={columnData.content}
        renderLinkToRecord={({ record, children }) => {
          return (<InternalLink
            record={record}
            classes=""
            children={children} 
          />)
        }}
        renderInlineRecord={({ record }) => {
          return null;
        }}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case "DatoCmsInlineVideoEmbed":
              return <InlineVideoEmbed videoData={record} />
            case  "DatoCmsInlineImage":
              return <InlineImage imageData={record.image} />
            case  "DatoCmsInlineLinkCard":
              return <InlineLinkCard cardData={record} />
            case  "DatoCmsInlineStatistic":
              return <InlineStatistic statData={record} />
            case  "DatoCmsInlineEveryactionForm":
              return <InlineEveryactionForm formData={record} />
            case  "DatoCmsInlineQuote":
              return <InlineQuote quoteData={record} />
            case  "DatoCmsInlineSocialMediaEmbed":
              return <InlineSocialMediaEmbed socialMediaData={record} />
            case  "DatoCmsLtaRotatingTabsWrapper":
              return <LTARotatingTabs rotatingTabsData={record} />
            default:
              return null;
          }
        }}
      />
    </div>
  )
}
