import React, { useEffect, useMemo, useRef } from 'react';
import SectionLayout from "../SectionLayout"
import './index.scss'
import SVGMap from '../SVGMap';
import InlineImage from '../InlineImage';
import { cardSelector } from '../DynamicSlider/utils/cardSelector';


function SuperCard({card, setActive}) {

  const cardRef = useRef(null)
  const cardObserver = useRef(null);
  
  const mapData = {
    mapPoints: card.mapPoints,
    showContent: false
  }

  const setUrlAnchor = (anchor) => {
      let urlNoAnchor = window.location.href.split('#')[0]
      const newUrl = anchor ? urlNoAnchor + "#" + anchor : urlNoAnchor
      setTimeout(() => {
        window.history.replaceState('', document.title, 
          newUrl
        );
      }, 200);
  }

  useEffect(() => {

    // options for interaction observer
    let observerOptions = {
      threshold: 0,
      rootMargin: "-49.9% 0% -49.9% 0%"
    }

    // create observer
    cardObserver.current = new IntersectionObserver((entries) => {

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActive()
          if (card.navSlug && !window.scrollingInto) {
            
            setUrlAnchor(card.navSlug)
          } 
        } else {
          setTimeout(() => {
            if (window.location.href.split('#')[1] == card.navSlug) {
              setUrlAnchor('')
            }
          }, 500);
        }
      });
    }, observerOptions);

    if (cardObserver.current && cardRef.current) {
      cardObserver.current.observe(cardRef.current)
    }
    return () => {
      if (cardObserver.current) {
        cardObserver.current.disconnect()
      }
    }
  }, [])


  return ( 
    <li ref={cardRef} className={`supercard supercard--theme-${card.backgroundColor}`} id={card.navSlug}>
      <div className={`supercard__header ${!card.headerPhoto && card.mapPoints ? 'supercard__header--only-map': ''}`} >

        { card.headerPhoto && (
          <InlineImage imageData={card.headerPhoto} classes="supercard__header-image" />
        )}

        {card.mapPoints.length > 0 && (
          <SVGMap mapData={{...mapData, parent: 'superCard'}}/>
        )}
      </div>

      <div className={`supercard__content ${card.mapPoints.length > 0 ? "supercard__content--header-map": ""}`}>
        {card.mainContent.map(mc => (
          <SectionLayout
          key = {mc.id}
          layout={mc.layout}
          singleColumnOnMobile={mc.singleColumnOnMobile}
          verticalPadding={mc.verticalPadding}
          columns={mc.columns}
        />
        ))}
      </div>
    </li>
  );
}

export default SuperCard;