
import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import "./index.scss"

export default function InlineVideo({videoData}) {

  const videoRef = useRef(null);
  
  
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const hlsUrl = videoData.video.streamingUrl;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(hlsUrl);
        hls.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // For Safari and other browsers with native HLS support
        video.src = hlsUrl;
      }
    }
  }, []);

  return (
    <div style={{ position: "absolute", top: 0,
          left: 0, bottom: 0, right: 0, overflow: "hidden" }}>
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      ></video>
    </div>
  );
}