import React, { useState } from "react"
import {
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa"
import { StructuredText } from "react-datocms"
import "./index.scss"

import InternalLink from "../InternalLink"
import BuildingSVG from '../../images/svg/Building-Authentic-Democracy.svg';
import GrowingSVG from '../../images/svg/Growing-civic-organizations.svg';
import ReiningSVG from '../../images/svg/Reining-in-corporate-power.svg';
import ShapingSVG from '../../images/svg/Shaping-a-people_s-economy.svg';

const mod = (n, m) => ((n % m) + m) % m

const LTARotatingTabs = ({ rotatingTabsData }) => {
  const { tabs, title } = rotatingTabsData
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [rotationDegrees, setRotationDegrees] = useState(0)

  const handleItemClick = newIndex => {
    if (newIndex === selectedIndex) return

    const anglePerItem = 360 / tabs.length

    // Calculate current and target positions in 0-360 range
    const currentPosition = ((rotationDegrees % 360) + 360) % 360
    const targetRotation = newIndex * anglePerItem

    // Calculate the difference between target and current
    let diff = targetRotation - currentPosition

    // Adjust the difference to take the shortest path
    if (diff > 180) {
      diff -= 360
    } else if (diff < -180) {
      diff += 360
    }

    // Calculate new absolute rotation
    const newRotation = rotationDegrees + diff

    setRotationDegrees(newRotation)
    setSelectedIndex(newIndex)
  }

  const getColor = si => {
    let res
    switch (si) {
      case "Building Authentic Democracy":
        res = "#FD495C"
        break;
        break
      case "Shaping a People’s Economy":
        res = "#1F1646"
        break;
        break
      case "Reining in Corporate Power":
        res = "#655DC6"
        break;
        break
      case "Growing Civic Organizations":
        res = "#18988B"
        break;
        break

      default:
        res = "#18988B"
        break;
        break
    }
    return res
  }

  const getIcon = index => {
    let res
    switch (tabs[index].strategicImperative) {
      case "Building Authentic Democracy":
        res = <img className="agenda-tabs__icon" src={BuildingSVG} alt="Building Authentic Democracy" />
        break;
      case "Shaping a People’s Economy":
        res = <img className="agenda-tabs__icon" src={ShapingSVG} alt="Shaping a people_s economy" />
        break;
      case "Reining in Corporate Power":
        res = <img className="agenda-tabs__icon" src={ReiningSVG} alt="Reining in corporate power" />
        break;
      case "Growing Civic Organizations":
        res = <img className="agenda-tabs__icon" src={GrowingSVG} alt="Growing civic organizations" />
        break;

      default:
        res = <img className="agenda-tabs__icon" src={BuildingSVG} alt="Building Authentic Democracy" />
        break;
    }
    return res
  }

  return (
    <div className="agenda-tabs">

      <h3 className="agenda-tabs__heading">{title}</h3>

      <div className="agenda-tabs__circle">
        <div
          className="agenda-tabs__wheel"
          style={{
            transform: `rotate(${-rotationDegrees}deg)`,
          }}
        >
          {tabs.map((tab, index) => {
            const angleStep = 360 / tabs.length
            const angle = angleStep * index + 90 // Start from bottom
            const radian = angle * (Math.PI / 180)
            const radius = 60
            let x = (Math.cos(radian) * radius) 
            let y = (Math.sin(radian) * radius)
            if (selectedIndex%2 == 0) {
              if (Math.abs(x) < 10) {
                x = 0;
                if (selectedIndex === index) {
                  x = -8
                }
              }
            } else {
              if (Math.abs(y) < 10) {
                y = 0;
                if (selectedIndex === index) {
                  y = -8
                }
              }
            }

            return (
              <div
                key={`tabCircle-${index}`}
                className={`agenda-tabs__circle-tab ${
                  selectedIndex === index ? "selected" : ""
                }`}
                style={{
                  transform: `translate(${x + 90}px, ${y + 90}px)`,
                  backgroundColor: getColor(tab.strategicImperative),
                }}
                onClick={() => handleItemClick(index)}
              >
                <div
                  className="icon-container"
                  style={{
                    transform: `rotate(${rotationDegrees}deg)`,
                  }}
                >
                  <span>{getIcon(index)}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="agenda-tabs__tab-wrapper">
          
        {tabs.map((tab, index) => (
          <div
            key={tab.headline}
            className={`agenda-tabs__tab position-${mod(index-selectedIndex, 4)}`}>
            <h4 className="agenda-tabs__tab-heading" style={{ color: getColor(tab.strategicImperative) }}>
              {tab.headline}
            </h4>

            <div className="agenda-tabs__tab-text">
              <StructuredText
                data={tab.description}
                renderLinkToRecord={({ record, children }) => {
                  return (
                    <InternalLink record={record} classes="" children={children} />
                  )
                }}
              />
              </div>

          </div>
        ))}
      </div>
      <div
        className="agenda-tabs__arrows"
      >
        <FaArrowLeft
          onClick={() => handleItemClick(mod(selectedIndex + 1, 4))}
          onMouseOver={({target})=>target.style.color="#FD495C"}
          onMouseOut={({target})=>target.style.color="grey"}
          color="grey"
        />
        <FaArrowRight
          onClick={() => handleItemClick(mod(selectedIndex - 1, 4))}
          onMouseOver={({target})=>target.style.color="#FD495C"}
          onMouseOut={({target})=>target.style.color="grey"}
          color="grey"
        />
      </div>
    </div>
  )
}

export default LTARotatingTabs
