import React, { useEffect, useRef, useState } from "react"
import SuperCard from "../SuperCard"
// import Slider from "react-slick"
// import ReactSlidy from 'react-slidy'
import "./index.scss"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
const SuperCardsSection = ({ superCardsData }) => {

  const superCardsRef = useRef(null)
  // const [currentSlide, setCurrentSlide] = useState(0)
  const [currentCardIndex, setCurrentCardIndex] = useState(0)

  const cards = superCardsData.cards
  
  useEffect(() => {

    const anchor = window.location.hash.substring(1)
    const cardIndexWithAnchor = cards.findIndex(c => c.navSlug == anchor)
    if ( cardIndexWithAnchor != -1 ) {
      window.scrollingInto = true
      setTimeout(() => {
        document.getElementById(anchor)?.scrollIntoView()
      }, 500);
    }

  }, [])

  const handleSetActive = (i) => {
    const anchor = window.location.href.split('#')[1]
    if (anchor == cards[i].navSlug) {
      setTimeout(() => {
        window.scrollingInto = false
      }, 1500);
    }
    setCurrentCardIndex(i)
  }

  return (
    <div ref={superCardsRef} className="supercards page-section__content">

      <nav className="supercards__nav">
        <ul>
          {cards.map((card, index) => (
            <li key={card.navSlug} className={`supercards__nav-item`}>
              <a href={`#${card.navSlug}`} className={`supercards__nav-link ${currentCardIndex == index ? "supercards__nav-link--current" : ""} supercards__nav-link--theme-${card.backgroundColor}`}>
                <span className="supercards__nav-label">{card.navLabel}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <ul className="supercards__card-wrapper">
        {cards.map((card, i) => (
          <SuperCard 
            setActive={() => handleSetActive(i)}
            card={card} 
            key={card.navSlug} 
          />
        ))}
      </ul>
    </div>
  )
}

export default SuperCardsSection
