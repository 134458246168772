import React from "react"
import { Link } from "gatsby"
import PageSection from "../PageSection"
import InlineImage from "../InlineImage"
import UJHero from "../UtilityJustice/UJHero"
import "./index.scss"
import InlineVideo from "../InlineVideo"

function layoutClasses(layout) {
  return "resource-hero--layout-".concat(layout)
}

function HeroSelector({ resourceType, pageTitle, pageTagline, content }) {
  if (content.useCustomHero && content.useCustomHero == "utility_justice") {
    return <UJHero resourceType={resourceType} title={pageTitle} />
  } else {
    return (
      <PageSection
        sectionClasses={["resource-hero", layoutClasses(content.layout)].join(
          " "
        )}
        colorTheme={content.backgroundColor}
      >
        <nav className="resource-hero__nav">
          <Link to="/resources" className="resource-hero__breadcrumb">
            ‹ Resources
          </Link>
        </nav>

        <div
          className={`resource-hero__text ${
            content.foregroundImage
              ? "resource-hero__text--has-foreground-image"
              : ""
          }`}
        >
          <p className="resource-hero__label">
            {resourceType.replaceAll("-", " ")}
          </p>

          <h1 className="resource-hero__title">{pageTitle}</h1>

          <p className="resource-hero__tagline">{pageTagline}</p>
        </div>

        {content.backgroundImage && (
          <InlineImage
            imageData={content.backgroundImage}
            classes="resource-hero__background"
          />
        )}
        {content.backgroundVideo && (
          <div className="resource-hero__background-video">
            <InlineVideo
              videoData={content.backgroundVideo}
            />
          </div>
        )}

        {content.foregroundImage && (
          <InlineImage
            imageData={content.foregroundImage}
            classes="resource-hero__foreground-image"
          />
        )}
      </PageSection>
    )
  }
}

export default function ResourceHero({
  resourceType,
  pageTitle,
  pageTagline,
  content,
}) {
  return (
    <HeroSelector
      resourceType={resourceType}
      pageTitle={pageTitle}
      pageTagline={pageTagline}
      content={content}
    />
  )
}
