import React from "react"
import Seo from "../components/seo"
import "../styles/pages/report.scss"
import { graphql, Link } from "gatsby"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import ShareWidget from "../components/ShareWidget"
import DynamicSlider from "../components/DynamicSlider"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ComponentSelector from "../components/ComponentSelector"
import ModularPageSections from "../components/ModularPageSections"
import ExtendedReportComponentSelector from "../components/ResourceComponents/ExtendedReportComponentSelector"

export default function FlexibleResource({ data, pageContext, location }) {
  const { slug, title, showFooterForm } = pageContext
  const { host, pathname } = location

  const {
    id,
    metadata,
    datePosted,
    thematicTags,
    reportSubtitle,
    typeOfResource,
    relatedProjects,
    relatedFocusAreas,
    pageComponents,
    pageComponentsInNav,
    pageBottomComponents
  } = data.basicReport.nodes[0]

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;

  const thematicTagsArray = thematicTags.split(", ")

  const reportTagsArray = [
    {
      tagLabel: "thematicTags",
      tags: [...thematicTagsArray],
    },
    {
      tagLabel: "relatedFocusAreas",
      tags: relatedFocusAreas.map(focusArea => focusArea.title),
    },
    {
      tagLabel: "relatedProjects",
      tags: relatedProjects.map(project => project.title),
    },
  ]

  // = = = = = = = = = = = = =
  // Sliders Props
  const resourcesSliderProps = {
    limit: 6,
    skip: id,
    cardColor: "#18988B",
    cardAlignment: "horizontal",
    headline: "<span>Related</span> Resources",
    link: [
      {
        label: "All resources",
        linkUrl: "/resources",
        isThisAButton: false,
      },
    ],
    relatedProjects: relatedProjects,
    relatedFocusAreas: relatedFocusAreas,
    contentType: [
      {
        contentType: "resource",
      },
    ],
    background: [
      {
        hasBackgroundImage: true,
      },
    ],
  }

  const pressSliderProps = {
    cardColor: "#1F1646",
    cardAlignment: "horizontal",
    headline: "In the news",
    relatedResources: [{title: title}],
    link: [],
    skip: id,
    contentType: [
      {
        contentType: "press",
      },
    ],
    background: [
      {
        hasBackgroundImage: false,
        backgroundColor: "#E5E4F3",
      },
    ],
  }

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />

        <ModularPageSections sectionsData={pageComponents} resourceType={typeOfResource} pageTitle={title} pageTagline={reportSubtitle} />

        <ExtendedReportComponentSelector components={pageBottomComponents} />

        <DynamicSlider component={resourcesSliderProps} />
        <DynamicSlider component={pressSliderProps} />

        <Footer showFooterForm={showFooterForm} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    basicReport: allDatoCmsResource(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        typeOfResource
        thematicTags
        title
        introText
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        downloads {
          buttonText
          componentId
          pdf {
            url
          }
        }
        reportSubtitle
        datePosted(formatString: "MMMM YYYY")
        headerBackgroundImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        slug
        showFooterForm
        featuredImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        relatedFocusAreas {
          title
        }
        relatedProjects {
          title
        }
        thematicTags
        reportSection {
          ... on DatoCmsSocialMediaEmbed {
            smEmbed
            componentId
          }
          ... on DatoCmsVideoEmbed {
            componentId
            embedCode
            youtubeOrVimeo
            imageOverlay
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsTextContent {
            content
            componentId
          }
        }
        pageComponents {
          ... on DatoCmsResourceHero {
            __typename
            id
            useCustomHero
            backgroundColor
            layout
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED)
              alt
              title
            }
            foregroundImage {
              gatsbyImageData(placeholder: BLURRED)
              alt
              title
            }
            backgroundVideo {
              video {
                streamingUrl
                mp4High: mp4Url(res: high)
                mp4Med: mp4Url(res: medium)
                mp4Low: mp4Url(res: low)
                duration
                thumbJpg: thumbnailUrl(format: jpg)
                thumbPng: thumbnailUrl(format: png)
                thumbGif: thumbnailUrl(format: gif)
              }
            }
          }
          ... on DatoCmsDownloadBar {
            __typename
            id
            introText {
              value
            }
            coverPhoto {
              gatsbyImageData(placeholder: BLURRED)
              alt
              title
            }
            colorTheme
            backgroundColor
            overlapWithPreviousComponent
            downloads {
              ... on DatoCmsReportDownload {
                __typename
                id
                buttonText
                pdf {
                  url
                }
              }
            }
          }
          ...PSAFeatureCta
          ... on DatoCmsPageSection {
            __typename
            id
            navIdSlug
            backgroundColor
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED)
              alt
              title
            }
            layouts {
              ...PSASectionLayout
              ...PSAInlinePhotoCardOverlay
              ...PSAChapterCover
              ...PSASuperCardsSection
              ...PSAMiscLinksSlider
              ... on DatoCmsMapWithClickableInfo {
                __typename
                id
                layout
                showContent
                mapPoints {
                  ...PSAMapPoint
                }
              }
              ... on DatoCmsModalsSlider {
                id
                __typename
                slides {
                  ... on DatoCmsModalSlide {
                    id
                    __typename
                    slideHeadline
                    slideText {
                      value
                    }
                    navSlug
                    slideImage {
                      gatsbyImageData(placeholder: BLURRED)
                      alt
                      title
                    }
                    modalContent {
                      ...PSASectionLayout
                    }
                  }
                }
              }
            }
          }
          ... on DatoCmsSectionsWithNavWrapper {
            __typename
            id
            components {
              ... on DatoCmsPageNavChapter {
                __typename
                id
                chapterLabel
                chapterType
                chapterContent {
                  ... on DatoCmsPageSection {
                    __typename
                    id
                    backgroundColor
                    backgroundImage {
                      gatsbyImageData(placeholder: BLURRED)
                      alt
                      title
                    }
                    layouts {
                      ...PSASectionLayout
                      ...PSAInlinePhotoCardOverlay
                      ...PSAChapterCover
                    }
                  }
                }
              }
            }
          }
          ...PSAExportSlider
          ...PSAScrollyElement
        }
        pageBottomComponents {
          ...PSAResourcePageCta
          ...PSAFeatureCta
          ... on DatoCmsReportSlider {
            componentId
            headline
            subtitle
            sectionBackground
            slides {
              headline
              introText
              modalContent
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsFormCtaBanner {
            componentId
            headline
            description
            donationForm
            formHeadline
            formLink
            amountButtons {
              amount
            }
            recurringDonationOptions {
              recurringDonation
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            rightBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            leftBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
