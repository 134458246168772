import * as React from "react"

import FeatureCTA from "../FeatureCTA"
import VideoEmbed from "../VideoEmbed"
import ReportSlider from "./ReportSlider"
import StatistcsRow from "../StatisticsRow"
import SocialMediaEmbed from "../SocialMediaEmbed"
import ExtendedReportTextContent from "./ExtendedReportTextContent"
import ResourcePageCTA from "./ResourcePageCTA"
import ExtendedReportColumnBlock from "./ExtendedReportColumnBlock"
import StateInterferenceMap from "../StateInterferenceMap"
import FormCTABanner from "../FormCTABanner"

export default function ExtendedReportComponentSelector({ components }) {
  return (
    <>
      {components?.map(component => {
        switch (component?.componentId) {
          case "statistic_row":
            return (
              <StatistcsRow key={component.componentId} component={component} />
            )

          case "feature_cta":
            return <FeatureCTA key={component.headline} component={component} />

          case "text_content":
            return (
              <ExtendedReportTextContent
                key={component.componentId}
                component={component}
              />
            )

          case "social_media_embed":
            return (
              <SocialMediaEmbed
                key={component.componentId}
                component={component}
              />
            )

          case "video_embed":
            return (
              <VideoEmbed key={component.embedCode} component={component} />
            )
          case "report_two_column_block":
            return (
              <ExtendedReportColumnBlock
                key={component.componentId}
                component={component}
              />
            )
          case "interference_map":
            return (
              <StateInterferenceMap key={component.componentId} component={component} />
            )
          case "resource_page_cta":
            return (
              <ResourcePageCTA key={component.headline} component={component} />
            )
          case "report_slider":
            return (
              <ReportSlider key={component.headline} component={component} />
            )
          case "form_cta_banner":
            return (
              <FormCTABanner key={component.headline} component={component} />
            )
          default:
            break
        }
      })}
    </>
  )
}
