import { useState, useEffect } from 'react';

const ParallaxStyle = (speed, parent) => {
  const [offset, setOffset] = useState(0);  

  const handleScroll = () => {
    setOffset(parent ? parent.getBoundingClientRect().top : window.scrollY * speed);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offset, speed]);

  return {
    style: {
      transform: `translateX(${offset}px)`,
    },
  };
};

export default ParallaxStyle;