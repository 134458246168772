import React from "react"
import "./index.scss"
import InlineImage from '../InlineImage'

function innerContent(replaceGridElement, sectionData, children) {
  if (replaceGridElement) {
    return (
      children
    )
  } else {
    return (
      <div className="page-section__content">
        { children }
      </div>
    )
  }
}

function backgroundImage(sectionData) {
  if (sectionData && sectionData.sectionData.backgroundImage) {
    return (
      <InlineImage imageData={sectionData.sectionData.backgroundImage} classes="page-section__background-image" />
    )
  }
}

function themeClasses(colorTheme) {
  return 'page-section--theme-'.concat(colorTheme);
}

export default function PageSection({ sectionClasses='', replaceGridElement=false, colorTheme, sectionData, children }) {
  return (
    <section className={`page-section ${[sectionClasses, themeClasses(colorTheme)].join(' ')}`} id={sectionData && sectionData.sectionData.navIdSlug ? sectionData.sectionData.navIdSlug : ""}>
      {backgroundImage(sectionData)}
      {innerContent(replaceGridElement, sectionData, children)}
    </section>
  )
}
